<!-- 充值弹窗 -->
<template>
  <div>
    <el-dialog
        width="30%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'充值':'充值'"
        @update:visible="updateVisible">

      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <!--<el-form-item label="现金金额：">-->
        <!--  <span style="font-size: 30px;font-weight: 700;color: #3f4155;">4800,00</span>-->
        <!--</el-form-item>-->
        <el-form-item label="充值方式">
          <div>
            <div v-for="(item,index) in list" :key="index" class="xuanxiang" :class="{xuanxiang1:index==isShow}" @click="activeItem(item,index)">
              <div style="display: flex;align-items: center;">
                <!--<img :src="item.img" alt="">-->
                <span>{{item.label}}</span>
              </div>
            </div>
          </div>
        </el-form-item>
        <!--微信支付-->
        <div v-if="isShow === 0">
          <el-form-item label="充值金额">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.money"
                placeholder="请输入充值金额"/>
          </el-form-item>
        </div>

        <!--转账支付-->
        <div v-if="isShow === 1">
          <el-form-item label="收款信息：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入收款信息"/>
          </el-form-item>
          <el-form-item label="银行：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入银行"/>
          </el-form-item>
          <el-form-item label="开户行：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入开户行"/>
          </el-form-item>
          <el-form-item label="账号：">
            <el-input
                clearable
                :maxlength="20"
                v-model="form.nickname"
                placeholder="请输入账号"/>
          </el-form-item>
          <el-form-item label="收款说明：">
            <el-input
                clearable
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5}"
                placeholder="请输入收款说明"
                v-model="form.textarea2">
            </el-input>
          </el-form-item>
        </div>

      </el-form>

      <div slot="footer" v-if="isShow === 0">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="nextStep">确定
        </el-button>
      </div>
      <div slot="footer" v-if="isShow === 1">
        <el-button
            @click="updateVisible(false)">确认已汇款
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        center
        title="充值"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <span>
        <el-row :gutter="15">
          <el-col :span="12">
            <div style="display: flex;align-items: center;">
              <span style="font-size: 14px;font-weight: 400;color: #666666;margin-right: 15px;">充值金额</span>
              <span style="font-size: 30px;font-weight: 700;color: #3f4155;">4800,00</span>
            </div>
          </el-col>
          <!--<el-col :span="12">-->
          <!--  <div style="display: flex;align-items: center;">-->
          <!--    <span style="font-size: 14px;font-weight: 400;color: #666666;margin-right: 15px;">现金金额</span>-->
          <!--    <span style="font-size: 30px;font-weight: 700;color: #3f4155;">5700,00</span>-->
          <!--  </div>-->
          <!--</el-col>-->
        </el-row>
        <el-row :gutter="15" style="margin-top: 15px;">
          <div style="display: flex;">
            <span style="font-size: 14px;font-weight: 400;color: #666666;margin-right: 15px;">收款二维码</span>
            <img src="@/assets/images/home/erweima.png" alt="" style="width: 161px;height: 161px;">
          </div>
        </el-row>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="fanhui">已完成充值</el-button>
      </span>
    </el-dialog>

  </div>

</template>

<script>
import {recharge} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: {
        money: '',
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      list:[
        {
          // img:require('../../../assets/images/home/gerenzhongxin/zhifuactive.png'),
          label:'支付宝支付'
        },
        // {
        //   img:require('../../../assets/images/home/gerenzhongxin/zhuanzhang.png'),
        //   label:'转账支付'
        // }
      ],
      isShow:0,

      dialogVisible: false

    };
  },

  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }
  },

  methods: {
    /* 保存编辑 */
    save() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     this.updateVisible(false);
      //     this.$emit('done');
      //     this.loading = false;
      //   } else {
      //     return false;
      //   }
      // });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    nextStep(){
      if(this.form.money == ''){
        this.$message.error('请输入充值金额');
      }else {
        // this.dialogVisible = true;
        // this.$emit('update:visible', false);
        let data = {
          price: this.form.money,
          from:'ali',
          return_url: window.location.href
        }
        recharge(data).then(res => {
          if(res.code === 200){
            this.form.money = '';
            window.location.href = res.data.return;
            this.$emit('update:visible', false);
          }else {
            this.$message.error(res.msg);
          }
        })
      }
    },

    fanhui(){
      this.dialogVisible = false;
      this.$emit('update:visible', false);
    },

    handleClose(){
      this.dialogVisible = false;
      this.$emit('update:visible', false);
    },

    //选择项事件
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
      if(item.label === '支付宝支付'){
        // this.list[0].img = require('../../../assets/images/home/gerenzhongxin/zhifuactive.png');
        // this.list[1].img = require('../../../assets/images/home/gerenzhongxin/zhuanzhang.png');
      }else if(item.label === '转账支付'){
        // this.list[0].img = require('../../../assets/images/home/gerenzhongxin/zhifu.png');
        // this.list[1].img = require('../../../assets/images/home/gerenzhongxin/zhuanzhuangactive.png');
      }
    },


  }
}
</script>

<style scoped lang="scss">
  .xuanxiang{
    display: inline-block;
    padding: 5px 15px 5px 15px;
    border: 1px solid #e9eaf1;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #3f4155;
    margin-right: 20px;
    cursor: pointer;
    img{
      width: 16px;
      height: 12px;
      margin-right: 15px;
    }
  }
  .xuanxiang1{
    background: #FFF4E3;
    border-color: #ffd38e;
    color: #FF9B05;
  }

</style>
